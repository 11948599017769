.box
  padding-left: 1em
  margin-left: 0.3em

.ellipsis
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.reverse-ellipsis
  text-overflow: clip
  position: relative


.reverse-ellipsis:before
  content: '\02026'
  position: absolute
  z-index: 1
  left: -1em
  background-color: inherit
  padding-left: 1em
  margin-left: 0.5em


.reverse-ellipsis span
  min-width: 100%
  position: relative
  display: inline-block
  float: right
  overflow: visible
  background-color: inherit
  text-indent: 0.5em


.reverse-ellipsis span:before
  content: ''
  position: absolute
  display: inline-block
  width: 1em
  height: 1.8em
  background-color: inherit
  z-index: 2
  left: -0.5em
