.product-detail-slider
    & li
        width: 16px !important
        height: 16px !important
        margin: 0 !important
    & button
        width: 16px !important
        height: 16px !important
        display: flex !important
        justify-content: center
        align-items: center
        padding: 0 !important
        &:before
            border-radius: 50%
            content: '' !important
            background: rgba(var(--color-main))
            opacity: 0.35 !important
            width: 6px !important
            height: 6px !important
            position: relative !important
    & li.slick-active button:before
        opacity: 1 !important
        background: rgba(var(--color-main)) !important
    & .slick-dots
        bottom: 15px
    & .slick-dots li button:hover:before
        background: rgba(var(--color-main)) !important
    & .slick-dots li button:focus:before
        background: rgba(var(--color-main)) !important    