.main-page-slider
  & li
    width: 50px !important
  & button
    width: 50px !important
    &:before
      content: '' !important
      background: #333333
      opacity: 0.6
      width: 50px !important
      height: 2px !important
      margin-top: 9px
  & li.slick-active button:before
    opacity: 1 !important
    background: #fff !important
  & .slick-dots
    bottom: 21px
  & .slick-dots li button:hover:before
    background: #fff !important
  & .slick-dots li button:focus:before
    background: #fff !important
  .slide
    transition: all 0.3s linear
  .side-slide
    transform: scale(0.9)
  .left-slide
    transform: scale(0.9) translateX(4%)
  .right-slide
    transform: scale(0.9) translateX(-4%)
  .slick-cloned
    & .left-slide
      transform: scale(0.9) translateX(-4%)
    & .right-slide
      transform: scale(0.9) translateX(4%)
  & .slick-list
    overflow: visible !important
  .slick-slide
    transition: all 0.3s linear
    &:not(.slick-active)
      opacity: 0.5
      & .slide
