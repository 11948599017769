
  
.statusbar-slider
  .slide
    width: calc(100% - 14px) !important
    transition: all 0.3s linear
  .left-slide
    transform: scale(0.8) translateX(13%)
  .right-slide
    transform: scale(0.8) translateX(-13%)
  & .slick-list
    overflow: visible !important
  .slick-slide
    transition: all 0.3s linear
    &:not(.slick-active)
      opacity: 0.5
      & .slide
        
        