@tailwind base
@tailwind components
@tailwind utilities

@import './fonts.sass'
@import '../components/pages/mainPage/Stories.sass'
@import '../components/pages/mainPage/Catalog/Popular.sass'
@import './productDetailSlider.sass'
@import './map.sass'
@import './reverseEllipsis.sass'
@import '../components/pages/cartPage/cartStep/sliders.sass'
@import '../components/pages/mainPage/Slider.sass'
@import '../components/pages/OrderPage/StatusBar/index.sass'

body
  overflow-x: hidden
  margin-right: calc(-1 * (100vw - 100%))
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  &::-webkit-scrollbar
    width: 10px
    @apply rounded-full
    &::-webkit-scrollbar-thumb
      @apply bg-gray-20
      @apply rounded-full

html
  font-family: 'Ubuntu', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

.noscroll
  margin: 0
  height: 100%
  overflow: hidden

.story-progress-bar
  transition: .5s linear

.story-playing
  position: absolute
  top: calc(50vh - min(90vh,900px) / 2)
  left: calc(50vw - min(60vh,600px) / 2)
  z-index: 201
  border: none !important
  border-radius: 20px !important
  width: min(60vh,600px) !important
  height: min(90vh,900px) !important
  overflow: hidden
  padding: 0 !important

.story-playing-mobile
  position: absolute
  top: 0
  left: 0
  z-index: 201
  border: none !important
  border-radius: 0 !important
  width: 100vw !important
  height: 100vh !important
  overflow: hidden
  padding: 0 !important

.story-playing-desktop-vk
  position: absolute
  top: calc(50vh - min(90vh,700px) / 2)
  left: calc(50vw - min(60vh,466px) / 2)
  z-index: 201
  border: none !important
  border-radius: 20px !important
  width: min(60vh,466px) !important
  height: min(90vh,700px) !important
  overflow: hidden
  padding: 0 !important

.container
  max-width: 1440px
  margin: 0 auto
  padding: 0 75px

@media screen and ( max-width: 1024px )
  .container
    padding: 0 15px

.no-scroll-bar
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.pretty-scroll
  &::-webkit-scrollbar
    width: 3px
    @apply rounded-full
    &::-webkit-scrollbar-thumb
      @apply bg-gray-20
      @apply rounded-full

.pretty-scroll2
  &::-webkit-scrollbar
    width: 15px
    @apply rounded-full
  &::-webkit-scrollbar-thumb
    border-left: 3px solid #EEEEEE
    background-clip: padding-box
    @apply bg-transparent

.pretty-scroll3
  &::-webkit-scrollbar
    display: none

.lines-ellipsis
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: 1
  -webkit-box-orient: vertical

.lines-ellipsis--2
  -webkit-line-clamp: 2

// Убираем стрелки у input[type=number]
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

// iOS align date input to the left
input::-webkit-date-and-time-value
  text-align: left

/* Firefox */
input[type=number]
  -moz-appearance: textfield

.searchBlockInHeader
  animation: animate-search-block .4s forwards

@keyframes animate-search-block
  0%
    opacity: 0.5
    transform: translateY(-15px)
  50%
    opacity: 1
  100%
    opacity: 1
    transform: translateY(0)

.react-datepicker-wrapper
  width: 100%

.react-datepicker__triangle
  display: none

.react-datepicker__header
  background: transparent !important
  border-bottom: none !important

.react-datepicker
  border-radius: 20px !important
  border: none !important
  padding: 11px 18px 25px !important

.react-datepicker-popper
  z-index: 10 !important

.dark .react-datepicker
  background: #BDBDBD !important

.react-datepicker__day-name
  font-weight: 700

.react-datepicker__day--selected
  background: rgba(var(--color-main)) !important

.dark .react-datepicker__day--outside-month, .dark .react-datepicker__day--disabled
  color: #848484 !important

.order
  &__statuses-trace
    &::before
      content: ""
      display: block
      position: absolute
      height: 5px
      bottom: 5px
      width: 100%
      @apply bg-main

      .order__statuses-trace--status__container--warning &
        @apply bg-yellow

    &--status
      .order__statuses-trace--processing &
        &:last-of-type
          &::before, &::after
            content: ""
            display: block
            position: absolute
            height: 5px
            bottom: 5px
            width: 50%
            @apply right-0

          &::before
            @apply bg-light dark:bg-dark

          &::after
            right: 7px
            border-radius: 999px
            @apply bg-main opacity-25

      &__container
        .order__statuses-trace--finished &
          &:last-of-type
            .order__statuses-trace--status
              &::after
                content: ""
                display: block
                position: absolute
                height: 5px
                bottom: 5px
                right: 0
                width: 50%
                @apply bg-light dark:bg-dark

    &__arrow
      .order__statuses-trace--finished &
        display: none

@media screen and ( max-width: 767px )
  #vk_community_messages
    display: none
