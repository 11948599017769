.ymaps-2-1-79-balloon
  box-shadow: none !important

.about .ymaps-2-1-79-balloon__content
  padding: 0 !important
  margin: 0 !important
  //width: 400px !important
  font: inherit !important

.about .ymaps-2-1-79-balloon__layout
  background: transparent !important
  border: none !important
  border-radius: 15px
  //width: 400px !important

.ymaps-2-1-79-balloon__tail
  height: 0 !important

.ymaps-2-1-79-balloon__content > *
  width: auto !important
  overflow: visible !important
  height: auto !important
  font: inherit !important

.ymaps-2-1-79-inner-panes
  overflow: visible !important

.payment .ymaps-2-1-79-balloon__content
  padding: 0 !important
  margin: 0 !important
  font: inherit !important

.payment .ymaps-2-1-79-balloon__layout
  background: transparent !important
  border: none !important
  border-radius: 15px

.address-popup
  .ymaps_maps-button, .ymaps_maps-zoom__plus, .ymaps_maps-zoom__minus
    min-width: 40px !important
    height: 40px !important

  .ymaps_maps-button__icon
    width: 30px !important
    height: 30px !important

  .ymaps_maps-button__icon
    padding: 6px 7px 4px 3px !important

  .ymaps-2-1-79-controls__toolbar
    margin-top: 0 !important

  .ymaps-2-1-79-controls__control_toolbar
    position: absolute !important
    right: 0 !important
    top: 341px !important
    margin-right: 15px !important

  .ymaps-2-1-79-copyright
    margin-right: 15px !important

  .ymaps-2-1-79-controls__control
    inset: 50% auto auto 15px !important
    right: 15px !important

  .ymaps_maps-zoom
    position: absolute !important
    top: 231px !important
    right: 0 !important
    padding: 40px 0 !important
    width: 40px !important
    height: 10px !important

  @media screen and ( max-width: 860px )
    .ymaps-2-1-79-controls-pane
      top: unset !important
      bottom: 0 !important

    .ymaps-2-1-79-controls__control_toolbar
      bottom: 43px !important
      top: unset !important

    .ymaps-2-1-79-copyright
      top: -54px !important
      float: left !important
      margin-left: 15px !important

    .ymaps_maps-zoom
      display: none !important

    .ymaps_maps-button
      min-width: 45px !important
      height: 45px !important

    .ymaps_maps-button__icon
      width: 35px !important
      height: 35px !important
      padding: 6px 7px 4px 3px !important
